import {
  isBillingInfo,
  isMessageQueueState,
  BillingInfo,
  MessageQueueState,
  MessageRequest,
} from "../types";

type QueueListener = (queuedCount: number) => void

const POLL_DELAY_MS = 500;
const POLL_TIMEOUT_ATTEMPTS = 8;

export async function verifyPassword(password: string): Promise<boolean> {
  const url = `${getHostname()}/api/verify-password`;

  const response = await fetch(url, {
    headers: {
      'Authorization': password
    }
  });

  console.log({ response });

  return response.status === 200;
}

export async function getState(password: string): Promise<MessageQueueState> {
  const url = `${getHostname()}/api/state`;

  const response = await fetch(url, {
    headers: {
      'Authorization': password
    }
  }).then(response => response.json());

  if (!isMessageQueueState(response)) {
    throw new TypeError(`Received invalid response: ${JSON.stringify(response)}`);
  }

  return response;
}

export async function pollQueueState(password: string, listener: QueueListener) {
  let previousQueued = -1;
  let attemptsRemaining = POLL_TIMEOUT_ATTEMPTS;

  while (attemptsRemaining > 0) {
    const state = await getState(password);
    if (!state.isBusy) {
      return;
    }

    const currentQueued = state.countQueued;
    listener(state.countQueued);

    if (currentQueued === previousQueued) {
      attemptsRemaining--;
    } else {
      previousQueued = currentQueued;
      attemptsRemaining = POLL_TIMEOUT_ATTEMPTS;
    }

    await pause(POLL_DELAY_MS);
  }

  throw new Error(`MessageQueue never made progress after checking ${POLL_TIMEOUT_ATTEMPTS} times`);
}

async function pause(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function sendMessages(request: MessageRequest, password: string): Promise<void> {
  const url = `${getHostname()}/api/jobs`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': password
    },
    body: JSON.stringify(request)
  });
  await response.json();
}

export async function fetchJobBilling(request: MessageRequest, password: string): Promise<BillingInfo> {
  const url = `${getHostname()}/api/job-billing`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': password
    },
    body: JSON.stringify(request)
  });
  const responseJson = await response.json();

  if (!isBillingInfo(responseJson)) {
    throw new TypeError(`Received invalid response: ${JSON.stringify(responseJson)}`);
  }

  return responseJson;
}

function getHostname(): string {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3000';
  } else {
    return '';
  }
}
