import { useEffect, useState } from 'react';
import styled from 'styled-components';

import AppContainer from '../components/AppContainer';
import { Recipient, BillingInfo, MessageRequest } from '../types';
import FormElement from '../components/FormElement';
import { fetchJobBilling } from '../messaging/api';

interface Props {
  recipients: Recipient[]
  message: string
  password: string
  onBack: () => void
  onSend: () => void
}

const ConfirmationView: React.FC<Props> = ({ recipients, message, password, onBack, onSend }) => {
  const [loading, setLoading] = useState<boolean>(true);

  const [billingInfo, setBillingInfo] = useState<BillingInfo | null>(null);

  useEffect(() => {
    setLoading(true);

    const request: MessageRequest = {
      message,
      recipients
    };

    fetchJobBilling(request, password)
      .then(billing => {
        setLoading(false);
        setBillingInfo(billing);
      });
  }, [recipients, message, password, setBillingInfo, setLoading]);

  return (
    <AppContainer>
      <FormElement title={`Recipients (${recipients.length})`}>
        <details>
          <summary>View recipients</summary>
          <RecipientList>
            {recipients.map((recipient, idx) => (
              <li key={`${idx}*${recipient.number}`}>
                {formatPhoneNumber(recipient.number)}
              </li>
            ))}
          </RecipientList>
        </details>
      </FormElement>

      <FormElement title="Message">
        <MessageContainer>{message}</MessageContainer>
      </FormElement>

      {!!billingInfo && !loading ? (
        <>
          <FormElement title="Cost to send">
            {formatTotalCost(billingInfo)} <CostDetails>{formatCostDetails(billingInfo)}</CostDetails>
          </FormElement>

          <FormElement title="Twilio account balance">
            {formatTwilioBalance(billingInfo)}
          </FormElement>
        </>
      ) : (
        <>
          <FormElement title="Cost to send">
            Loading...
          </FormElement>

          <FormElement title="Twilio account balance">
            Loading...
          </FormElement>
        </>
      )}

      <div>
        <wired-button onClick={onBack}>Back</wired-button>
        <wired-button onClick={onSend}>Send</wired-button>
      </div>
    </AppContainer>
  );
};

export default ConfirmationView;

const RecipientList = styled.ul`
  margin: 0;
`;

const MessageContainer = styled.div`
  white-space: pre-wrap;
`;

const CostDetails = styled.span`
  color: #999;
`;

function formatPhoneNumber(phoneNumber: string) {
  const matchResults = phoneNumber.match(/^\+1([0-9]{3})([0-9]{3})([0-9]{4})$/);
  if (matchResults) {
    return `(${matchResults[1]}) ${matchResults[2]}-${matchResults[3]}`;
  } else {
    return phoneNumber;
  }
}

function formatTotalCost(billingInfo: BillingInfo): string {
  return `$${billingInfo.costToSend.total}`;
}

function formatCostDetails(billingInfo: BillingInfo): string {
  const { recipients, segments, costPerSegment } = billingInfo.costToSend;

  return [
    `= ${recipients} recipients `,
    `x ${segments} message segments `,
    `x $${costPerSegment} per segment`
  ].join('');
}

function formatTwilioBalance(billingInfo: BillingInfo): string {
  return `$${billingInfo.accountBalance}`;
}
