import { Recipient } from '../types';
import { parseRecipients } from '../messaging/parseRecipients';

export interface AppState {
  status: Status
  password: string
  recipientsText: string
  recipients: Recipient[]
  message: string
  queuedCount: number
  showInvalidPasswordBanner: boolean
}

export type AppAction =
  | { type: 'LOGIN_SUCCESSFUL', password: string }
  | { type: 'LOGIN_FAILED' }
  | { type: 'CONTINUE', recipientsText: string, message: string }
  | { type: 'BACK' }
  | { type: 'SEND_MESSAGES' }
  | { type: 'SENDING_FINISHED' }
  | { type: 'UPDATE_QUEUED_COUNT', queuedCount: number }
  | { type: 'RESET' }

export enum Status {
  LoggedOut = 1,
  Ready,
  Confirmation,
  Sending,
  Finished,
}

export const INITIAL_STATE: AppState = {
  status: Status.LoggedOut,
  password: '',
  recipientsText: '',
  recipients: [],
  message: '',
  queuedCount: 0,
  showInvalidPasswordBanner: false,
};

export function appReducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case 'LOGIN_SUCCESSFUL':
      return {
        ...state,
        status: Status.Ready,
        password: action.password,
        showInvalidPasswordBanner: false,
      };
    case 'LOGIN_FAILED':
      return {
        ...state,
        showInvalidPasswordBanner: true,
      };
    case 'CONTINUE':
      return {
        ...state,
        status: Status.Confirmation,
        recipientsText: action.recipientsText,
        recipients: parseRecipients(action.recipientsText),
        message: action.message,
      };
    case 'BACK':
      return {
        ...state,
        status: Status.Ready,
      };
    case 'SEND_MESSAGES':
      return {
        ...state,
        status: Status.Sending,
        queuedCount: state.recipients.length,
      };
    case 'SENDING_FINISHED':
      return {
        ...state,
        status: Status.Finished,
      };
    case 'UPDATE_QUEUED_COUNT':
      return {
        ...state,
        queuedCount: action.queuedCount,
      };
    case 'RESET':
      return {
        ...state,
        status: Status.Ready,
        recipientsText: '',
        recipients: [],
        message: '',
        queuedCount: 0,
      };
    default:
      return state;
  }
}
