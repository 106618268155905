import { useReducer } from 'react';
import 'wired-elements';
import styled from 'styled-components';

import {
  Status,
  appReducer,
  INITIAL_STATE
} from './state/appReducer';
import {
  pollQueueState,
  sendMessages,
  verifyPassword
} from './messaging/api';
import MessageView from './views/MessageView';
import ConfirmationView from './views/ConfirmationView';
import FinishedView from './views/FinishedView';
import SendingView from './views/SendingView';
import LoginView from './views/LoginView';

function App() {
  const [state, dispatch] = useReducer(appReducer, INITIAL_STATE);
  const { status } = state;

  async function handleLogin(password: string) {
    const isLoggedIn = await verifyPassword(password);

    if (isLoggedIn) {
      dispatch({ type: 'LOGIN_SUCCESSFUL', password });
    } else {
      dispatch({ type: 'LOGIN_FAILED' });
    }
  }

  function handleContinue(recipientsText: string, message: string) {
    dispatch({
      type: 'CONTINUE',
      recipientsText,
      message
    });
  }

  function handleBack() {
    dispatch({ type: 'BACK' });
  }

  async function handleSend() {
    const { recipients, message, password } = state;
    const messageRequest = { message, recipients };

    dispatch({ type: 'SEND_MESSAGES' });

    await sendMessages(messageRequest, password);
    await pollQueueState(
      password,
      (queuedCount: number) => dispatch({ type: 'UPDATE_QUEUED_COUNT', queuedCount })
    );

    dispatch({ type: 'SENDING_FINISHED' });
  }

  function handleReset() {
    dispatch({ type: 'RESET' });
  }

  return (
    <Container>
      <AppTitle>Mass Texter</AppTitle>

      {status === Status.LoggedOut && (
        <LoginView
          showInvalidPasswordBanner={state.showInvalidPasswordBanner}
          onLogin={handleLogin}
        />
      )}

      {status === Status.Ready && (
        <MessageView
          recipientsText={state.recipientsText}
          message={state.message}
          onContinue={handleContinue}
        />
      )}

      {status === Status.Confirmation && (
        <ConfirmationView
          recipients={state.recipients}
          message={state.message}
          password={state.password}
          onBack={handleBack}
          onSend={handleSend}
        />
      )}

      {status === Status.Sending && (
        <SendingView
          countQueued={state.queuedCount}
          countTotal={state.recipients.length}
        />
      )}

      {status === Status.Finished && (
        <FinishedView
          onReset={handleReset}
        />
      )}
    </Container>
  );
}

export default App;

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 40px auto;
  padding: 0 40px;
  box-sizing: border-box;
  background-color: #eee;
`;

const AppTitle = styled.h1`
  margin: 0 0 20px;
  line-height: 1.0;
`;
