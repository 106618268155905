import parsePhoneNumber from 'libphonenumber-js'

import { Recipient, PhoneNumber } from "../types";

export function parseRecipients(recipientsText: string): Recipient[] {
  return recipientsText
    .split('\n')
    .map(line => line.trim())
    .filter(line => line !== '')
    .map(line => parseRecipient(line));
}

function parseRecipient(line: string): Recipient {
  if (line.includes(',')) {
    const [name, rawNumber] = line
      .split(',')
      .map(piece => piece.trim());

    return {
      name,
      number: formatPhoneNumber(rawNumber)
    };
  } else {
    return {
      name: null,
      number: formatPhoneNumber(line)
    };
  }
}

function formatPhoneNumber(rawPhoneNumber: string): PhoneNumber {
  const phoneNumber = parsePhoneNumber(rawPhoneNumber, 'US');
  if (!phoneNumber) {
    throw new TypeError(`Invalid phone number: ${rawPhoneNumber}`);
  }
  return phoneNumber.format("E.164");
}
