import styled from "styled-components";

import AppContainer from "../components/AppContainer";
import ViewTitle from "../components/ViewTitle";

interface Props {
  onReset: () => void
}

const FinishedView: React.FC<Props> = ({ onReset }) => {
  return (
    <AppContainer>
      <ViewTitle>Finished!</ViewTitle>
      <Action>
        <wired-button onClick={onReset}>Send another message</wired-button>
      </Action>
    </AppContainer>
  );
};

export default FinishedView;

const Action = styled.div`
  margin-top: 80px;
`;
