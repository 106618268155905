import styled from "styled-components";

interface Props {
  title: string
}

const FormElement: React.FC<Props> = ({ title, children }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Container>
  )
}

export default FormElement;

const Container = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

const Content = styled.div`
  wired-input {
    width: 100%;
    box-sizing: border-box;
  }

  wired-textarea {
    width: 100%;
    box-sizing: border-box;
  }
`;
