import { useRef } from 'react';
import styled from 'styled-components';

import AppContainer from '../components/AppContainer';
import FormElement from '../components/FormElement';

interface Props {
  showInvalidPasswordBanner: boolean
  onLogin: (password: string) => void
}

const LoginView: React.FC<Props> = ({ showInvalidPasswordBanner, onLogin }) => {
  const passwordInput = useRef<any>();

  function handleLogin() {
    const password: string = passwordInput.current.value;

    onLogin(password);
  }

  return (
    <AppContainer>

      {showInvalidPasswordBanner && (
        <InvalidPasswordBanner>Invalid password!</InvalidPasswordBanner>
      )}

      <FormElement title="Password">
        <wired-input
          type="password"
          ref={passwordInput}
        />
      </FormElement>

      <div>
        <wired-button onClick={handleLogin}>Login</wired-button>
      </div>

    </AppContainer>
  );
};

export default LoginView;

const InvalidPasswordBanner = styled.p`
  margin: 0 0 1rem;
  color: #ff0000;
  font-size: 20px;
`;
