import AppContainer from "../components/AppContainer";
import ViewTitle from "../components/ViewTitle";

interface Props {
  countQueued: number
  countTotal: number
}

const SendingView: React.FC<Props> = ({ countQueued, countTotal }) => {
  const countProcessed = countTotal - countQueued;

  return (
    <AppContainer>
      <ViewTitle>Sending...</ViewTitle>

      <wired-progress
        value={countProcessed}
        min={0}
        max={countTotal}
      />

    </AppContainer>
  );
};

export default SendingView;
