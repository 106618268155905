import { useEffect, useRef } from 'react';

import FormElement from '../components/FormElement';
import AppContainer from '../components/AppContainer';

interface Props {
  recipientsText: string
  message: string
  onContinue: (recipientsText: string, message: string) => void
}

const MessageView: React.FC<Props> = ({ recipientsText, message, onContinue }) => {
  const recipientsInput = useRef<any>();
  const messageInput = useRef<any>();

  useEffect(() => {
    recipientsInput.current.value = recipientsText;
    messageInput.current.value = message;

  }, [recipientsText, message]);

  function handleContinue() {
    const recipientsText: string = recipientsInput.current.value;
    const message: string = messageInput.current.value;

    onContinue(recipientsText, message);
  }

  return (
    <AppContainer>

      <FormElement title="Recipients">
        <wired-textarea
          ref={recipientsInput}
          placeholder="555-555-5555&#10;999-999-9999"
        />
      </FormElement>

      <FormElement title="Message Text">
        <wired-textarea
          ref={messageInput}
          placeholder="This is my message!"
        />
      </FormElement>

      <div>
        <wired-button onClick={handleContinue}>Continue</wired-button>
      </div>

    </AppContainer>
  );
}

export default MessageView;
