import styled from "styled-components";

const AppContainer: React.FC = ({ children }) => {
  return (
    <wired-card elevation="5" style={{ width: '100%', boxSizing: 'border-box' }}>
      <CardContent>
        {children}
      </CardContent>
    </wired-card>
  );
};

export default AppContainer;

const CardContent = styled.div`
  margin: -4px;
  padding: 20px;
  background-color: white;
`;
