export interface MessageQueueState {
  countSuccessful: number
  countFailed: number
  countQueued: number
  isBusy: boolean
}

export interface MessageRequest {
  message: string;
  recipients: Recipient[];
}

export interface Recipient {
  name: string | null
  number: PhoneNumber
}

export type PhoneNumber = string

export interface BillingInfo {
  costToSend: MessageSendCost
  accountBalance: Currency
}

export interface MessageSendCost {
  total: Currency
  recipients: number
  segments: number
  costPerSegment: Currency
}

export type Currency = string

export function isMessageQueueState(state: unknown): state is MessageQueueState {
  if (typeof state !== 'object' || state === null) {
    return false;
  }

  if (!hasOwnProperty(state, 'countSuccessful') || typeof state.countSuccessful !== 'number') {
    return false;
  }

  if (!hasOwnProperty(state, 'countFailed') || typeof state.countFailed !== 'number') {
    return false;
  }

  if (!hasOwnProperty(state, 'countQueued') || typeof state.countQueued !== 'number') {
    return false;
  }

  if (!hasOwnProperty(state, 'isBusy') || typeof state.isBusy !== 'boolean') {
    return false;
  }

  return true;
}

export function isBillingInfo(billingInfo: unknown): billingInfo is BillingInfo {
  if (typeof billingInfo !== 'object' || billingInfo === null) {
    return false;
  }

  if (!hasOwnProperty(billingInfo, 'costToSend') || !isCostToSend(billingInfo.costToSend)) {
    return false;
  }

  if (!hasOwnProperty(billingInfo, 'accountBalance') || typeof billingInfo.accountBalance !== 'string') {
    return false;
  }

  return true;
}

function isCostToSend(cost: unknown): cost is MessageSendCost {
  if (typeof cost !== 'object' || cost === null) {
    return false;
  }

  if (!hasOwnProperty(cost, 'total') || !isCurrency(cost.total)) {
    return false;
  }

  if (!hasOwnProperty(cost, 'recipients') || typeof cost.recipients !== 'number') {
    return false;
  }

  if (!hasOwnProperty(cost, 'segments') || typeof cost.segments !== 'number') {
    return false;
  }

  if (!hasOwnProperty(cost, 'costPerSegment') || !isCurrency(cost.costPerSegment)) {
    return false;
  }

  return true;
}

function isCurrency(value: unknown): value is Currency {
  if (typeof value !== 'string') {
    return false;
  }

  if (!value.match(/^[0-9]+\.[0-9]+$/)) {
    return false;
  }

  return true;
}

function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}
